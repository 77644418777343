import { defineStore } from "pinia";

export const useProfileStore = defineStore('profile', {
    persist: true,
    state: () => ({
        profile: null,
    }),
    getters: {
        logined(state) {
            return state.profile != null;
        }  
    },
    actions: {
        logout(){
            this.profile=null;
        } 
    }
})