import { createRouter, createWebHistory } from 'vue-router'
import { useProfileStore } from '@/stores/profileStore';

const routes = [{
  path: '/:pathMatch(.*)*',
  name: 'NotFound',
  component: () => import('../views/NotFound.vue'),
  meta: { title: 'صفحه یافت نشد' }

},

{
  path: '/',
  component: () => import('../layout/DefultLayout.vue'), children: [
    {
      path: '',
      name: 'home',
      component: () => import('../views/HomeView.vue'),
      meta: {}
    }, {
      path: 'about',
      name: 'about',
      component: () => import('../views/AboutView.vue'),
      meta: { title: 'درباره ما' }
    }, {
      path: 'contact',
      name: 'contact',
      component: () => import('../views/ContactView.vue'),
      meta: { title: 'تماس با ما' }
    }, {
      path: 'blog',
      name: 'blog',
      component: () => import('../views/blog/BlogView.vue'),
      meta: { title: 'دانستنی ها' }
    },
    {
      path: 'blog/:id/:title',
      name: 'blogDetails',
      component: () => import('../views/blog/BlogDetails.vue'),
      meta: { title: 'دانستنی ها' }
    },
    {
      path: 'market',
      name: 'market',
      component: () => import('../views/market/MarketHome.vue'),
      meta: { title: 'نمایندگان فروش' }
    },
    {
      path: 'market/:id/:name',
      name: 'marketDetails',
      component: () => import('../views/market/MarketDetails.vue'),
      meta: { title: 'نمایندگان فروش' }
    },
    {
      path: 'profile',
      name: 'profile',
      component: () => import('../views/profile/MyProfile.vue'),
      meta: { title: 'مشخصات کاربر', requiresAuth: true }
    }]
}, {
  path: '/login',
  component: () => import("@/layout/LoginLayout.vue"),
  children: [
    {
      path: '',
      name: 'login',
      component: () => import("@/views/auth/LoginPage.vue"),
      meta: {
        title: 'وارد شوید'
      }
    }
  ]
},
{
  path: '/manager', component: () => import('../layout/ManagerLayout.vue'), meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'managerHome',
      component: () => import('../views/manager/managerHome.vue')
    }, {
      path: 'member',
      name: 'managerMember',
      component: () => import('../views/manager/member/MemberList.vue')
    }, {
      path: "member/:id",
      name: 'managerMemberDetails',
      component: () => import("../views/manager/member/MemberDetails.vue")
    }, {
      path: 'market',
      name: 'managerMarket',
      component: () => import('../views/manager/market/MarketList.vue')
    }, {
      path: "market/:id",
      name: 'managerMarketDetails',
      component: () => import("../views/manager/market/MarketDetails.vue")
    },
    {
      path: "blog/",
      name: 'managerBlog',
      component: () => import("../views/manager/blog/BlogList.vue")
    },
    {
      path: "blog/:id",
      name: 'managerBlogDetails',
      component: () => import("../views/manager/blog/BlogDetails.vue")
    },
  ]
}
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "is-active", // active class for non-exact links.
  linkExactActiveClass: "is-exactive", // active class for *exact* links
  scrollBehavior(to, from) {
    if (
      (from.name != undefined & from.name != to.name) |
      (from.params.key != undefined & from.params.key != to.params.key)
    ) {
      var elmnt = document.getElementById("topScroll");
      if (typeof elmnt != "undefined" && elmnt != null) {

        return {
          top: elmnt.offsetTop + (elmnt.offsetHeight),
          behavior: 'smooth',
        }
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  const dataProfile = useProfileStore();

  let reLogin = false;
  if (dataProfile.logined) {
    reLogin = (Date.parse(dataProfile.profile.expTime) - Date.now()) / (24 * 60 * 60 * 1000) < 0;
  }
  if (to.meta.requiresAuth & (!dataProfile.logined | reLogin)) {
    next({ path: '/login', query: { last: to.fullPath } });
  } else {
    let documentTitle = `${process.env.VUE_APP_TITLE}`;
    if (to.meta.title) {
      documentTitle = `${to.meta.title} - ${documentTitle}`;
    }
    document.title = documentTitle;
    next()
  }
});

export default router

