<template>
  <RouterView />
  <div v-if="deferredPrompt" class="fixed bottom-3 left-3">
    <div
      class="bg-gray-50 rounded-lg shadow-md p-4 border border-gray-200 w-64"
    >
      <div class="relative">
        <img src="@/assets/pwa.png" alt="pwa" class="h-8 mx-auto" />
        <p class="block mb-1 text-gray-900 leading-tight text-sm">
          برای راحتی و بازدید مجدد از سایت ما، یک میانبر ایجاد نمایید
        </p>
        <div class="flex flex-wrap w-full">
          <button
            class="rounded-r justify-center px-2 py-1 bg-green-500 text-white flex font-bold w-2/3 hover:bg-green-700 hover:text-white duration-150 transition-all"
            @click="install"
          >
            ایجاد میان‌بر
          </button>
          <button
            class="rounded-l justify-center px-2 py-1 bg-red-500 text-white flex font-bold w-1/3 hover:bg-red-700 hover:text-white duration-150 transition-all"
            @click="dismiss"
          >
            انصراف
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

export default {
  setup() {
    let deferredPrompt = ref();
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      deferredPrompt.value = e;
    });
    window.addEventListener("appinstalled", () => {
      deferredPrompt.value = null;
    });
    async function dismiss() {
      deferredPrompt.value = null;
    }
    async function install() {
      deferredPrompt.value.prompt();
    }
    return { deferredPrompt, install, dismiss };
  },
};
</script>
